import {request} from './request'

export function getMine(){
    return request({
        url:"/mine"
    })
}
//我的红包
export function getRedPacket(status){
    return request({
        url:"/mine/redPacket",
        params:{
            status
        }
    })
}

//签到
export function signIn(){
    return request({
        url:"/mine/signIn"
    })
}

//获取认证信息
export function getAuth(){
    return request({
        url:"/mine/auth"
    })
}

//认证
export function auth(authName,authPicA,authPicB,authType){
    return request({
        method:"post",
        url:"/mine/auth",
        data:{
            authName,authPicA,authPicB,authType
        }
    })
}

// 资金记录 总收益
export function getMoneys(){
    return request({
        url:"/mine/fund"
    })
}

//实名认证
export function authentication(){
    return request({
        url:"/mine/auth"
    })
}

export function getPic(){
    return request({
        url:"/mine/setInfo"
    })
}


//站内信
export function getNotices(page,limit){
    return request({
        url:"/mine/notices",
        params:{
            page,
            limit
        }
    })
}
//站内信详情
export function getNoticeInfo(id){
    return request({
        url:"/mine/notices/detail",
        params:{
            id
        }
    })
}
//我的入伙
export function myInvest(page,limit,status){
    return request({
        url:'/mine/invest',
        params:{
            page,limit,status
        }
    })
}

//我的定制
export function myBespoke(page,limit,status){
    return request({
        url:'/mine/investSelf',
        params:{
            page,limit,status
        }
    })
}
//确认定制
export function confirmBespoke(id,pwd){
    return request({
        method:'post',
        url:'/mine/investSelf',
        data:{
            id:JSON.stringify(id),
            fundPwd:pwd
        }
    })
}
//我的邀请
export function getInvite(page,limit,begin,end){
    return request({
        url:'/mine/subordinate',
        params:{
            page,limit,begin,end
        }
    })
}
//设置手势
export function setGesture(gesturePwd){
    return request({
        method:'post',
        url:`/mine/gesture?gesture=${gesturePwd}`
    })
}
// 获取手势
export function getGesture(){
    return request({
        url:'/mine/gesture'
    })
}
//资金记录
export function getMoneyRecord(page,limit,begin,end){
    return request({
        url:'/mine/fund/pcList',
        params:{
            page,limit,begin,end
        }
    })
}

//是否有未读站内信
export function getMsg(){
    return request({
        url:'/mine/notices/read'
    })
}
//将站内信置为已读
export function setMsg(){
    return request({
        method:'post',
        url:'/mine/notices/read'
    })
}