import {request} from './request'

export function getAdmissionDetail(page,limit,method,sort,title,operator,days,redPacket,status){
    return request({
        url:"/invest",
        params:{
            page,limit,method,sort,title,operator,days,redPacket,status
        }
    })
}
export function getAdmission(id){
    return request({
        url:"/invest/detail",
        // responseType:'arraybuffer',
        params:{
            id:id,
        }
    })
}
export function ruHuo(id,amount,fundPwd){
    return request({
        method:"post",
        url:"/invest/balance",
        data:{
            id:id,
            amount:amount,
            fundPwd:fundPwd,
        }
    })
}
//红包
export function redPacketAdd(id,redPacket,fundPwd){
    return request({
        method:"post",
        url:"/invest/redPacket",
        data:{
            id:id,
            redPacket:redPacket,
            fundPwd:fundPwd,
        }
    })
}