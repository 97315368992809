<template>
  <div class="bodys">
    <div class="home">
      <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb">
        <el-breadcrumb-item class="dangq">当前位置：</el-breadcrumb-item>
        <el-breadcrumb-item class="dangq"
          ><router-link to="/">汇成国际</router-link></el-breadcrumb-item
        >
        <el-breadcrumb-item class="dangq"
          ><a href="javascript:;" style="user-select: all">{{
            xiang1.title
          }}</a></el-breadcrumb-item
        >
      </el-breadcrumb>
      <div class="cont">
        <div class="cont_left">
          <div class="left_top">
            <div class="top_name">
              <div class="name_left">
                <div class="left_1">垫本预付类产品</div>
                <div class="left_2">
                  {{
                    xiang1.status == "0"
                      ? "募集中"
                      : xiang1.status == "1"
                      ? "操作中"
                      : xiang1.status == "2"
                      ? "已清盘"
                      : "已取消"
                  }}
                </div>
              </div>
              <div class="name_right" v-if="xiang1.status == '0'">
                募集期截止:
                <van-count-down
                  :time="xiang1.raise_end * 1000 - date"
                  format="DD天HH小时mm分钟"
                />
              </div>
              <div class="name_right" v-else-if="xiang1.status == '1'">
                距到期截止:
                <van-count-down
                  :time="xiang1.end_at * 1000 - date"
                  format="DD天HH小时mm分钟"
                />
              </div>
              <div class="name_right" v-else-if="xiang1.status == '2'">
                清盘时间:{{
                  (xiang1.end_at * 1000) | format("YYYY-MM-DD HH:mm")
                }}
              </div>
            </div>
            <div class="hengx"></div>
            <div class="center_name">
              <div class="center_left">
                <div class="yuqi">
                  <i v-if="xiang1.status == '0'">预期入伙收益率(年): </i>
                  <i v-else-if="xiang1.status == '1'">当前入伙收益率(年): </i>
                  <i v-else>到期入伙收益率(年): </i>
                  <span class="reds"
                    >{{ xiang1.annual_profit / 100 }}% +
                    {{ xiang1.float_profit / 100 }}%</span
                  >
                </div>
                <div class="yuqi" v-if="xiang1.status == '0'">
                  目标募集金额:
                  <span>{{ xiang1.target_amount / 100 }}元</span>
                </div>
                <div class="yuqi" v-else>
                  实际募集金额:
                  <span>{{ xiang1.amount / 100 }}元</span>
                </div>
                <div class="yuqi">
                  操作周期: <span>{{ xiang1.operate_days }}天</span>
                </div>
                <div class="yuqi" v-if="xiang1.status == '0'">
                  剩余可入金额:
                  <span>{{ xiang1.balance_amount / 100 }}元</span>
                </div>
                <div class="yuqi" v-else-if="xiang1.status == '1'">
                  当前合伙盈亏:
                  <span>{{ xiang1.diff_amount / 100 }}元</span>
                </div>
                <div class="yuqi" v-else-if="xiang1.status == '2'">
                  到期产品净值:
                  <span>{{ xiang1.final_amount / 100 }}元</span>
                </div>
              </div>
              <div class="center_right">
                <div class="tongji" v-if="xiang1.status == '0'">
                  <div class="jindus">募集进度</div>
                  <van-circle
                    v-model="xiang1.planned_speed / 100"
                    :rate="100"
                    :clockwise="false"
                    :text="(xiang1.planned_speed / 100).toFixed(1) + '%'"
                    size="56px"
                    layer-color="#C8DFFF"
                    color="#1578FF"
                    :stroke-width="60"
                  />
                </div>
                <div
                  class="yuqi"
                  v-else-if="xiang1.status == '1'"
                  style="width: 100%"
                >
                  当前合伙净值: <span>{{ xiang1.final_amount / 100 }}元</span>
                </div>
                <div
                  class="yuqi"
                  v-else-if="xiang1.status == '2'"
                  style="width: 100%"
                >
                  到期盈亏:
                  <span>{{ xiang1.diff_amount / 100 }}元</span>
                </div>
                <div class="ruhuo" @click="wantAdd" v-if="xiang1.status == '0'">
                  立即入伙
                </div>
              </div>
            </div>
          </div>
          <div class="cont_center">
            <div :class="tab == 1 ? 'tabj2 tabj' : 'tabj'" @click="tabjs">
              产品信息
              <div class="lanh" v-show="lanh"></div>
            </div>
            <div :class="tab == 2 ? 'tabj2 tabj' : 'tabj'" @click="tabjs2">
              入伙记录
              <div class="lanh" v-show="lanh2"></div>
            </div>
            <div
              :class="tab == 3 ? 'tabj2 tabj' : 'tabj'"
              @click="tabjs3"
              v-show="xiang1.status == '2'"
            >
              交易记录
              <div class="lanh" v-show="lanh3"></div>
            </div>
          </div>
          <div class="left_bottom">
            <div class="content" v-show="tab == 1">
              <div class="information" @click.stop="information0">
                <div class="information_top">
                  <img
                    :src="zhan0 == false ? zhankai : zhankai2"
                    class="zhankai"
                    alt=""
                  />
                  <div class="information_title">产品基本信息</div>
                </div>
                <div class="information_nei" v-show="zhan0">
                  <div class="ji_zi">
                    目标募集金额{{
                      xiang1.target_amount / 100
                    }}元，产品最小募集合伙金额{{
                      xiang1.min_amount / 100
                    }}元，产品最大风险{{ xiang1.max_risk }}%，
                    产品操作期预期收益{{ xiang1.exp_income }}%
                  </div>
                  <table class="table">
                    <tr>
                      <td class="td1">角色</td>
                      <td class="td2">承担风险</td>
                      <td class="td3">预期总收益(元)</td>
                    </tr>
                    <tr>
                      <td class="td1">合伙人</td>
                      <td class="td2">0%</td>
                      <td class="td3">
                        {{ xiang1.operator_annual_profit / 100 }}+{{
                          xiang1.investor_float_profit / 100
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td class="td1">操盘手</td>
                      <td class="td2">{{ xiang1.risk_fund_rate / 100 }}%</td>
                      <td class="td3">{{ xiang1.operator_income / 100 }}</td>
                    </tr>
                  </table>
                </div>
              </div>
              <div class="information" @click.stop="information1">
                <div class="information_top">
                  <img
                    :src="zhan1 == false ? zhankai : zhankai2"
                    class="zhankai"
                    alt=""
                  />
                  <div class="information_title">盈亏分配</div>
                </div>
                <div class="information_nei" v-show="zhan1">
                  <table class="table">
                    <tr>
                      <td class="td4">角色</td>
                      <td class="td5">收益分配</td>
                    </tr>
                    <tr>
                      <td class="td6">合伙人</td>
                      <td class="td7">
                        <p>固定年化收益率{{ xiang1.annual_profit / 100 }}%</p>
                        <p>浮动收益分配:从产品收益中分</p>
                        <p>成，固定分{{ xiang1.float_profit / 100 }}%</p>
                      </td>
                    </tr>
                    <tr>
                      <td class="td4">操盘手</td>
                      <td class="td5">产品盈利分给合伙人后的剩余部分</td>
                    </tr>
                  </table>
                </div>
              </div>
              <div class="information" @click.stop="information2">
                <div class="information_top">
                  <img
                    :src="zhan2 == false ? zhankai : zhankai2"
                    class="zhankai"
                    alt=""
                  />
                  <div class="information_title">产品其他信息</div>
                </div>
                <div
                  class="information_nei information_nei_bottom"
                  v-show="zhan2"
                >
                  <div class="other">
                    <div class="other_2">
                      <div class="other_3">
                        <div>目标募集总金额:</div>
                        <div>¥{{ xiang1.target_amount / 100 }}</div>
                      </div>
                      <div class="other_3">
                        <div>募集成功后提前进入操作期:</div>
                        <div>
                          {{ xiang1.advance_operate == 0 ? "否" : "是" }}
                        </div>
                      </div>
                      <div class="other_3">
                        <div>产品当前状态:</div>
                        <span class="txt" v-if="xiang1.status == 0"
                          >募集中</span
                        >
                        <span class="txt" v-if="xiang1.status == 1"
                          >开始操作</span
                        >
                        <span class="txt" v-if="xiang1.status == 2"
                          >已结束</span
                        >
                        <span class="txt" v-if="xiang1.status == 3"
                          >项目取消</span
                        >
                      </div>
                      <div class="other_3">
                        <div>合伙人收益超预期可提前清盘:</div>
                        <span class="txt" v-if="xiang1.advance_end == 0"
                          >否</span
                        >
                        <span class="txt" v-if="xiang1.advance_end == 1"
                          >是</span
                        >
                      </div>
                      <div class="other_3">
                        <div>产品描述:</div>
                      </div>
                    </div>
                    <div class="other_2">
                      <div class="other_3"></div>
                      <div class="other_3">
                        <div>募集开始时间:</div>
                        <div>
                          {{
                            (xiang1.raise_begin * 1000)
                              | format("YYYY-MM-DD HH:mm")
                          }}
                        </div>
                      </div>
                      <div class="other_3">
                        <div v-if="xiang1.status == 0">计划募集结束时间:</div>
                        <div v-else>实际募集结束时间:</div>
                        <div>
                          {{
                            (xiang1.raise_end * 1000)
                              | format("YYYY-MM-DD HH:mm")
                          }}
                        </div>
                      </div>
                      <div class="other_3">
                        <div v-if="xiang1.status == 0">计划操作开始时间:</div>
                        <div v-else>实际操作开始时间:</div>
                        <div>
                          {{
                            (xiang1.begin_at * 1000)
                              | format("YYYY-MM-DD HH:mm")
                          }}
                        </div>
                      </div>
                      <div class="other_3">
                        <div v-if="xiang1.status != '2'">计划操作结束时间:</div>
                        <div v-else-if="xiang1.status == '2'">
                          实际操作结束时间:
                        </div>
                        <div>
                          {{
                            (xiang1.end_at * 1000) | format("YYYY-MM-DD HH:mm")
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="describe">{{ desc }}</div>
                </div>
              </div>
              <div class="information" @click.stop="information3">
                <div class="information_top">
                  <img
                    :src="zhan3 == false ? zhankai : zhankai2"
                    class="zhankai"
                    alt=""
                  />
                  <div class="information_title">交易风格</div>
                </div>
                <div class="information_nei" v-show="zhan3">
                  <div class="transaction">
                    <div class="transaction_1">
                      交易平台: <span>{{ xiang1.trading_platform }}</span>
                    </div>
                    <div class="transaction_2">
                      买卖方向: <span>{{ xiang1.trading_direction }}</span>
                    </div>
                  </div>
                  <div class="transaction">
                    <div class="transaction_1">
                      投资策略: <span>{{ xiang1.investment }}</span>
                    </div>
                    <div class="transaction_2">
                      投资范围: <span>{{ xiang1.invest_scope }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="content2" v-show="tab == 2">
              <div class="content2_cont">
                <div class="introduce">
                  <img src="../../../assets/img/ren.png" class="ren" alt="" />
                  <div class="introduce_zi">
                    已入伙: <span>{{ investUserCount }}</span> 人
                  </div>
                  <div class="introduce_zi">
                    募集 <span>{{ xiang1.amount / 100 }}</span> 元
                    <!-- (含风险金400,000.00元) -->
                  </div>
                </div>
                <!-- 列表标题 -->
                <div class="list_title">
                  <div>用户名</div>
                  <div>入伙金额</div>
                  <div>入伙时间</div>
                  <div>备注</div>
                </div>
                <!-- 循环列表 -->
                <div class="list" v-for="(item, index) in xiang2" :key="index">
                  <div>{{ item.name }}</div>
                  <div>¥{{ item.amount / 100 }}</div>
                  <div>
                    {{ (item.create_at * 1000) | format("YYYY-MM-DD HH:mm") }}
                  </div>
                  <div>{{ item.remark }}</div>
                </div>

                <!-- 分页 -->
                <!-- <el-pagination
                  class="page"
                  :page-size="limit"
                  background
                  layout="prev, pager, next"
                  :total="count"
                  :current-page="page"
                  @current-change="handleSizeChange"
                >
                </el-pagination> -->
              </div>
            </div>
            <!-- 交易记录 -->
            <div class="content3" v-if="tab == 3">
              <div class="tables">
                <!-- 表头
                <div class="table-header">
                  <table cellspacing="0" cellpadding="0" border="0">
                    <colgroup>
                      <col v-for="(item, index) in tableHead" :key="index" />
                    </colgroup>
                  </table>
                </div> -->
                <div class="table-content">
                  <table cellspacing="0" cellpadding="0" border="0">
                    <colgroup>
                      <col v-for="(item, index) in tableHead" :key="index" />
                    </colgroup>
                    <thead>
                      <tr>
                        <th
                          colspan="1"
                          rowspan="1"
                          v-for="(item, index) in tableHead"
                          :key="index"
                        >
                          {{ item }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in tableData" :key="index">
                        <td
                          colspan="1"
                          rowspan="1"
                          v-for="(item2, index2) in item"
                          :key="index2"
                        >
                          {{ item2 }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="cont_right">
          <div class="gao_nei">
            <div class="gao_title">
              <div class="title_zi">最新产品</div>
              <div
                class="alls"
                @click="$router.replace('/joinRaise')"
                style="cursor: pointer"
              >
                全部<img
                  src="../../../assets/img/next2.png"
                  style="width: 10px"
                  alt=""
                />
              </div>
            </div>
            <div class="hengx2"></div>
            <div class="gao_list_top">
              <div>产品名称</div>
              <div>预期年化</div>
            </div>
            <div
              class="gao_list"
              v-for="(item, index) in list"
              :key="index"
              style="cursor: pointer; user-select: none"
              @click="
                $router.push({ path: '/collection', query: { id: item.id } })
              "
            >
              <div
                style="
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                "
              >
                {{ item.title }}
              </div>
              <div>{{ item.annual_profit / 100 }}%</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="joinNew_zhe" v-show="joinNew_zhe"></div>
    <div class="joinNew" v-show="joinNew">
      <div class="joinNew_title">请输入投资金额</div>
      <div class="joinNew_keyong">
        可输入金额(1-{{
          xiang1.balance_amount / 100 > user_balance / 100
            ? parseInt(user_balance / 100)
            : xiang1.balance_amount / 100
        }})
      </div>
      <div class="joinNew_list">
        <div class="joinNew_edu">
          账户余额: <span>{{ user_balance / 100 }}(元)</span>
        </div>
        <div class="joinNew_edu">
          请输入金额:
          <input
            type="text"
            v-model="value"
            placeholder="请输入金额"
            autocomplete="off"
            @input="formatValue(value)"
          />
        </div>
      </div>
      <div class="joinNew_list">
        <div class="joinNew_edu">
          预期收益: <span>{{ yqsy }}(元)</span>
        </div>
        <div class="joinNew_edu">
          操作结束时间:
          <span>{{
            (xiang1.operate_end * 1000) | format("YYYY-MM-DD HH:mm")
          }}</span>
        </div>
      </div>
      <div class="joinNew_bottom">
        <div class="joinNew_no" @click="joinNow_no">取消</div>
        <div class="joinNew_ok" @click="joinNow_ok">确定</div>
      </div>
    </div>
    <div class="fundPassword" v-show="fundPassword">
      <div class="fundPassword_title">资金密码</div>
      <div class="fundPassword_list">
        <div class="fundPassword_inp">资金密码：</div>
        <input
          class="fundPassword_pwd"
          type="password"
          v-model="password"
          placeholder="请输入资金密码"
          autocomplete="off"
        />
      </div>
      <router-link to="/vipCenter/changeMoneyPwd" class="fundPassword_wang">
        <img
          class="fundPassword_tu"
          src="../../../assets/img/key2.png"
          alt=""
        />
        <div class="fundPassword_tiao">忘记密码</div>
      </router-link>
      <div class="fundPassword_bottom">
        <div class="fundPassword_no" @click="fundPassword_no">取消</div>
        <div class="fundPassword_ok" @click="fundPassword_ok">确定</div>
      </div>
    </div>
    <!-- 红包 -->
    <el-dialog
      top="30vh"
      title="我的红包"
      :visible.sync="dialogVisible"
      width="300px"
      :show-close="false"
      center
      custom-class="redPacket"
    >
      <div class="ablePacket">
        <span>可用：</span>
        <el-select v-model="selectedRed" placeholder="请选择">
          <el-option
            v-for="item in ablePaclet"
            :key="item.id"
            :label="
              (item.amount / 100).toFixed(2) +
              '  ' +
              $moment(item.expire_at * 1000).format('YYYY-MM-DD') +
              '过期'
            "
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
        @click="
          dialogVisible = false;
          fundPassword = true;
          joinNew_zhe = true;
        "
      >
        选择
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getAdmission,
  ruHuo,
  getAdmissionDetail,
  redPacketAdd,
} from "@network/admissionDetail";
import { getMine, getRedPacket } from "@network/mine";
import { BASE_URL } from "@constants/constants";
export default {
  name: "Collection",
  data() {
    return {
      net: BASE_URL,
      zhankai: require("../../../assets/img/zhankai.png"),
      zhankai2: require("../../../assets/img/zhankai2.png"),
      lanh: true, //tab 横杠
      lanh2: false, //tab 横杠
      lanh3: false, //tab 横杠
      tab: 1, //tab 切换 当前
      zhan0: false,
      zhan1: false,
      zhan2: false,
      zhan3: false,
      c: 100,
      value: "", //入伙金额
      password: "",
      joinNew_zhe: false,
      joinNew: false,
      fundPassword: false,
      id: "",
      xiang1: {},
      xiang2: {},
      date: new Date(),
      investUserCount: "",
      desc: "",
      list: [], //最新产品
      user_balance: 0, //用户余额
      ablePaclet: [], //可用红包
      dialogVisible: false, //红包弹窗
      selectedRed: "", //选择的红包
      wb: {}, //excel
      moneyReg: /^\+?[1-9][0-9]*$/,

      page: 1,
      limit: 10,
      count: 0,
      tableData: [],
      tableHead: [
        '开仓时间',
        "交易品种",
        "交易类型",
        "交易量",
        "开仓价位",
        "平仓时间",
        "平仓价位",
        '手续费',
        '库存费',
        "交易盈亏",
      ],
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.getAdmission();
    this.invest();
    this.getRedPacket();
  },
  components: {},
  watch: {
    $route(n, o) {
      if (n.fullPath !== o.fullPath) {
        //监听路由参数是否变化
        this.getAdmission();
        this.invest();
        this.getRedPacket();
      }
    },
  },
  methods: {
    //处理当前页变动时触发的事件
    handleSizeChange(val) {
      this.page = val;
    },
    // 立即入伙
    wantAdd() {
      if (this.xiang1.red_packet == 1 && this.ablePaclet.length != 0) {
        this.dialogVisible = true;
        return false;
      }
      this.joinNew = true;
      this.joinNew_zhe = true;
    },
    //红包 可用
    getRedPacket() {
      getRedPacket("0").then((res) => {
        console.log(res);
        if (res.code != 0) {
          return false;
        } else {
          this.ablePaclet = res.data;
        }
      });
    },
    //最新产品
    invest() {
      getAdmissionDetail("1", "8", "1", "asc", "", "", "", "", "").then(
        (res) => {
          console.log(res);
          if (res.code != 0) {
            // if (res.code == 403) {
            //   this.$storage.remove("token");
            //   this.$message.error("请重新登录");
            //   this.$router.replace("/home");
            //   return false;
            // }
            this.$message.error({
              message: "获取失败",
              offset: 300,
            });
            return false;
          }
          this.list = res.data;
        }
      );
    },

    getAdmission() {
      getAdmission(this.$route.query.id).then((res) => {
        console.log(res);
        if (res.code != 0) {
          if (res.code == 403) {
            this.$storage.remove("token");
            this.$message.error({
              message: "请重新登录",
              offset: 300,
            });
            this.$router.replace("/login");
            return false;
          }
          return false;
        }
        this.user_balance = res.userBalance;
        this.xiang1 = res.invest;
        this.xiang2 = res.investUser;
        this.investUserCount = res.investUserCount;
        this.desc = res.invest.desc;
        this.tableData = JSON.parse(JSON.stringify(res.invest.excel));
      });
    },
    //金额格式化
    formatValue(val) {
      val = val.replace(/(^\s*)|(\s*$)/g, "");
      if (!val) return (this.value = "");
      val = val.replace(/[^\d.]/g, "");
      val = val.replace(/^\./g, "");
      val = val.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
      val = val.replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3");
      this.value = val;
    },

    joinNow_no() {
      this.joinNew = false;
      this.joinNew_zhe = false;
    },
    joinNow_ok() {
      if (this.value == "") {
        this.$message.error({
          message: "未输入金额",
          offset: 300,
        });
        return false;
      } else if (!this.moneyReg.test(this.value)) {
        this.$message.error({
          message: "请输入整数金额",
          offset: 300,
        });
        return false;
      } else if (
        parseFloat(this.value) < 1 ||
        this.value.slice(0, 1) == 0 ||
        parseFloat(this.value) > 10000000
      ) {
        this.$message.error({
          message: "请输入正确的金额",
          offset: 300,
        });
        return false;
      }
      this.joinNew = false;
      this.fundPassword = true;
    },
    //输入资金密码
    fundPassword_no() {
      this.fundPassword = false;
      this.joinNew_zhe = false;
    },
    fundPassword_ok() {
      if (this.password) {
        // 红包入伙
        if (this.xiang1.red_packet == 1) {
          redPacketAdd(this.id, this.selectedRed, this.password).then((res) => {
            console.log(res);
            if (res.code == 0) {
              this.$message.success({
                message: res.msg,
                offset: 300,
              });
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            } else {
              this.$message.error({
                message: res.msg,
                offset: 300,
              });
            }
          });
        } else {
          ruHuo(this.id, this.value * 100, this.password).then((res) => {
            console.log(res);
            if (res.code == 0) {
              this.$message.success({
                message: res.msg,
                offset: 300,
              });
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            } else {
              this.$message.error({
                message: res.msg,
                offset: 300,
              });
            }
          });
        }
      } else {
        this.$message.error({
          message: "未输入密码",
          offset: 300,
        });
      }
      this.fundPassword = false;
      this.joinNew_zhe = false;
    },
    information0() {
      this.zhan0 = !this.zhan0;
    },
    information1() {
      this.zhan1 = !this.zhan1;
    },
    information2() {
      this.zhan2 = !this.zhan2;
    },
    information3() {
      this.zhan3 = !this.zhan3;
    },
    tabjs() {
      this.lanh = true;
      this.lanh2 = false;
      this.lanh3 = false;
      this.tab = 1;
    },
    tabjs2() {
      this.lanh = false;
      this.lanh2 = true;
      this.lanh3 = false;
      this.tab = 2;
    },
    tabjs3() {
      this.lanh = false;
      this.lanh2 = false;
      this.lanh3 = true;
      this.tab = 3;
    },
  },

  computed: {
    //预期收益
    yqsy() {
      return (
        ((this.value * (this.xiang1.annual_profit / 10000)) / 365) *
        this.xiang1.operate_days
      ).toFixed(2);
    },
  },
};
</script>
<style lang='less' scoped>
@import "../../../assets/css/details/collection.less";
.ablePacket {
  display: flex;
  align-items: center;
  margin: 10px 0;
  span {
    white-space: nowrap;
  }
}
.bodys /deep/ .el-dialog__footer {
  cursor: pointer;
}
</style>
